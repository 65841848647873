
import { Component, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";

interface formData {
  employer: string | null;
  rut_company: string;
  name_company: string;
  name_employer: string;
  email_ncc: string | null;
}

@Component
export default class EmailNCC extends Mixins(FormValidator) {
  private block_submit: boolean = false;
  private no_data_dialog: boolean = false;
  private loading_empleadores: boolean = false;
  private options_able: Array<any> = [];

  protected formData: formData = {
    employer: null,
    rut_company: "",
    name_company: "",
    name_employer: "",
    email_ncc: null
  };

  private notify(message: string) {
    this.$q.notify({
      message: message,
      color: "principal",
      position: "top",
      timeout: 5000
    });
  }

  private cancelAttemp() {
    this.$store.dispatch("setStateToCero");
  }

  private emailAttemp() {
    //
    if (!this.formData || !this.formData.employer || !this.formData.email_ncc) {
      return this.notify("Debe seleccionar un empleador");
    }
    //
    this.$emit("successNcc", this.formData);
  }

  private showNcc() {
    this.$q.loading.show();
    this.$axios
      .get("/suac-data/getNcc", {
        params: {
          rut: this.$store.state.user.rut,
          empleador: this.formData.employer
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.formData.email_ncc = response.data;
      })
      .catch(error => {
        this.no_data_dialog = true;
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private getEmpleadores() {
    this.$q.loading.show();
    this.loading_empleadores = true;
    this.$axios
      .get("/suac-data/getEmpleadores", {
        params: {
          rut: this.$store.state.user.rut
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        if (response.data.length == 0) {
          this.cancelAttemp();
        }
        this.options_able = response.data;
      })
      .catch(error => {
        this.cancelAttemp();
      })
      .finally(() => {
        this.$q.loading.hide();
        this.loading_empleadores = false;
      });
  }

  private mounted() {
    this.getEmpleadores();
  }
}
